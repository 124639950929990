<template>
  <div>
    <div class="modal-cover"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h5 class="title">
          평가를 취소 하시겠습니까? </h5>
      </div>
      <div class="modal-center">
        <p>취소한 평가는 복구가 불가능합니다.<br/>
          평가를 정말 취소 하시겠습니까?</p>
      </div>
      <div class="modal-footer">
        <button class="custom-btn-box btn-close" @click="closeBtn">취소</button>
        <button class="custom-btn-box btn-registration" @click="removeBtn">평가 취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/firebase/firebaseError";

export default {
  name: 'ModalCancellation',
  data() {
    return {
      fbCollection: 'evaluations',
      kidsUid: this.$route.params.id,
      evaluationId: this.$route.params.evaluationId,
    }
  },
  props: {
    type: String
  },
  methods: {
    closeBtn() {
      const self = this;
      self.$emit('closeModal');
    },
    removeBtn() {
      const self = this;
      const db = firebase.firestore();
      const batch = db.batch();

      const kidsRef = db.collection("kids").doc(self.kidsUid);
      if(self.type === "offline"){
        batch.update(kidsRef, {isDoOffline: false});
        // console.log('오프라인 평가 취소 isDoOffline = false')
        } else if (self.type === "online") {
        batch.update(kidsRef, {isDoOnline: false});
        // console.log('온라인 평가 취소 isDoOnline = false')
        }

      db.collection(self.fbCollection)
          .doc(self.evaluationId)
          .delete();

      batch.commit().then(() => {
        self.$router.replace(`/kidsList`);
      })
          .catch((err) => {
            // 실패 후 실행할 코드
            self.isProgress = false;
            firebaseError(err);
          })
    }
  },
}
</script>

<style scoped>

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #FFFFFF;
  border-radius: 8px;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px;
  background: #F8F8FA;
}

.modal-center p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

.modal-center p span {
  color: #D23442;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  padding: 16px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-close {
  width: 84px;
  height: 40px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  font-weight: 400;
  border-radius: 4px;
  background: #ffffff !important;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
  color: rgba(24, 24, 25, 0.9);
}

.btn-registration {
  width: 84px;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
  color: white;
  background: #2B2346 !important;
}


</style>
