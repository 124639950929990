<template>
  <div>
    <div class="menu-background">
      <ul class="menu">
        <li>
          <a @click="routerPage('/intro')">K-COSA 소개</a>
        </li>
        <li>
          <router-link to=""
                       :class="{active : $route.path === '/kidsList'
                       || $route.path.includes('/choice')
                       || $route.path.includes('/online')
                       || $route.path.includes('/offline')}">평가시행
          </router-link>
        </li>
        <li>
          <a @click="routerPage('/manage')">평가관리</a>
        </li>
        <li>
          <a @click="routerPage('/reference')">교육자료</a>
        </li>
        <li>
          <a @click="routerPage('/qna')">문의사항</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavOnlyOperation',
  methods: {
    routerPage(page) {
      const self = this;
      self.$emit('modalControl', 1, page);
    },
  }
}
</script>

<style>
.menu-background {
  height: 68px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
}

.menu {
  width: 573px;
  height: 48px;
  color: #3F3F46;
  display: flex;
  justify-content: space-between;
}

.menu li {
  display: flex;
  align-items: center;
  height: 100%
}

.menu li a {
  padding: 12px 0;
}

.menu li a:hover, .menu li a:focus {
  color: #4C9AF5;
}

.active {
  color: #2E8AF4;
}
</style>
