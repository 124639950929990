<template>
  <div class="modal-cover">
    <div class="modal-warning" v-show="modalPage === 0">
      <div class="modal-center">
        <img src="@/assets/images/IA/icon_warning.png" alt="">
        <p>평가를 완료하지 않은 항목이 있습니다.</p>
      </div>
      <div class="footer-btn-box">
        <button class="check-btn" @click="checkBtn">확인</button>
      </div>
    </div>
    <div class="modal-warning" v-show="modalPage === 1">
      <div class="modal-header">
        <h5 class="title">
          평가를 완료하셨나요?
        </h5>
      </div>
      <div class="modal-center-second">
        <p>모든 항목의 평가가 완료 되었는지 확인해주세요.<br/>
          평가는 나중에 이어 할 수 있습니다.<br/>
          진행한 평가가 있다면, 임시저장 버튼을 꼭 눌러주세요.</p>
      </div>
      <div class="footer-btn-box">
        <button class="check-btn exit-btn" @click="checkBtn">닫기</button>
        <button class="check-btn" @click="cancelEvaluation">나가기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPageDeparture',
  props: {
    page: String
  },
  data() {
    return {
      modalPage: 0,
    }
  },
  methods: {
    checkBtn() {
      const self = this;
      if (self.modalPage === 0) {
        self.modalPage = 1;
      } else {
        self.$emit('closeModal');
      }
    },
    cancelEvaluation() {
      const self = this;
      self.$router.push(self.page);
    }
  }

}
</script>

<style scoped>

.modal-cover .modal-warning {
  position: absolute;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #FFFFFF;
  font-size: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.modal-warning .modal-header {
  height: 76px;
  padding: 24px 20px;
}

.modal-warning .modal-header .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.modal-cover .modal-warning .modal-center {
  height: 164px;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.modal-cover > .modal-warning .modal-center p {
  margin: 10px 0 4px
}

.modal-cover > .modal-warning .modal-center img {
  width: 42px;
  height: 42px;
}

.modal-cover .modal-warning .modal-center-second {
  height: 126px;
  background: #F8F8FA;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 30px;
  border-bottom: #E1E3E6 1px solid;
}

.modal-cover .modal-warning .footer-btn-box {
  height: 73px;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.modal-cover .modal-warning .footer-btn-box .check-btn {
  width: 84px;
  height: 40px;
  background: #2E8AF4;
  border-radius: 4px;
  font-weight: 400;
  line-height: 36px;
  color: #FFFFFF;
  border: 0;
}

.modal-cover .modal-warning .footer-btn-box .exit-btn {
  background: #FFFFFF;
  border: #E1E3E6 1px solid;
  color: #181819;
  margin-right: 12px;
}

</style>