<template>
  <div class="modal-cover">
    <div class="modal-container">
      <div class="modal-header">
        <h5 class="title">
          K-COSA 결과를 등록 하시겠습니까?
        </h5>
      </div>
      <div class="modal-center">
        <p>결과 등록 후 수정 되지 않습니다.<br/>
          결과는 평가관리 페이지에서 확인 가능합니다.</p>
      </div>
      <div class="footer-btn-box">
        <button class="check-btn exit-btn" @click="closeBtn">취소</button>
        <button class="check-btn" @click="makeScore">결과 등록</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalRegistration',
  methods: {
    closeBtn() {
      const self = this;
      self.$emit('closeModal', 0);
    },
    makeScore() {
      const self = this;
      self.$emit('makeScore', 3);
    }
  }
}
</script>

<style scoped>

.modal-cover .modal-container {
  position: absolute;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #FFFFFF;
  font-size: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.modal-container .modal-header {
  height: 76px;
  padding: 24px 20px;
}

.modal-container .modal-header .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.modal-cover .modal-container .modal-center {
  height: 104px;
  background: #F8F8FA;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 30px;
  border-bottom: #E1E3E6 1px solid;
}

.modal-cover .modal-container .footer-btn-box {
  height: 73px;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.modal-cover .modal-container .footer-btn-box .check-btn {
  width: 104px;
  height: 40px;
  background: #5AC820;
  border-radius: 4px;
  font-weight: 400;
  line-height: 36px;
  color: #FFFFFF;
  border: 0;
}

.modal-cover .modal-container .footer-btn-box .exit-btn {
  width: 84px;
  background: #FFFFFF;
  border: #E1E3E6 1px solid;
  color: #181819;
  margin-right: 12px;
}

</style>